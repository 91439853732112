<!-- TODO: FANCY DETAILS CONTENT TRANSITION + FINE TUNE CROSS ANIMATION -->

<template>
  <div>
    <div class="pt-header mb-xxxl">
      <div class="row">
        <div class="col-xxs-offset-2 col-xxs-20 col-m-13">
          <h1 class="page-title intro__title" v-if="content.headline">
            {{ content.headline }}
          </h1>

          <h2
            class="page-subtitle intro__subtitle alt-heading mb-xxl"
            v-if="content.subtitle"
          >
            {{ content.subtitle }}
          </h2>
        </div>
      </div>
    </div>

    <div class="mb-xxl" v-if="content.faq && content.faq.length > 0">
      <div
        v-for="(faq, i) in content.faq"
        :key="i"
        :class="{
          'mb-xxl': i < content.faq.length - 1,
        }"
      >
        <div class="row">
          <div class="col-xxs-20 col-xxs-offset-2 col-l-offset-2 col-l-7">
            <h3 class="h2">{{ faq.sectionLabel }}</h3>
          </div>
          <div class="col-xxs-20 col-xxs-offset-2 col-l-offset-1 col-l-12">
            <ul class="reset">
              <li v-for="(item, j) in faq.items" :key="j">
                <details class="expand-toggle">
                  <summary class="expand-toggle__content">
                    <div class="expand-toggle__content-wrapper">
                      <h4 class="expand-toggle__label" v-if="item.title">
                        {{ item.title }}
                      </h4>

                      <svg
                        class="expand-toggle__plus"
                        v-svg
                        size="0 0 32 32"
                        symbol="plus"
                      ></svg>
                    </div>
                  </summary>

                  <g-wysiwyg :wysiwyg="item.htmltext" class="expand__content" />
                </details>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <BigCTA v-if="!Array.isArray(content.cta)" :cta="content.cta" />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import BigCTA from '@/components/BigCTA.vue'

export default defineComponent({
  components: {
    BigCTA,
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
</script>
