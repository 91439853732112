










import Vue, { VueConstructor } from 'vue'
import { computed, defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Subpage from '@/views/pages/Subpage.vue'
import Expertise from '@/views/pages/Expertise.vue'
import About from '@/views/pages/About.vue'
import Solutions from '@/views/pages/Solutions.vue'
import Products from '@/views/pages/Products.vue'
import Contact from '@/views/pages/Contact.vue'
import FAQ from '@/views/pages/FAQ.vue'

export interface Pages {
  [key: string]: VueConstructor<Vue>
}

// prettier-ignore
const pages: Pages = {
  page: Subpage,
  expertise: Expertise,
  about: About,
  solutions: Solutions,
  products: Products,
  contact: Contact,
  faq: FAQ,
}

export default defineComponent({
  name: 'page',
  setup() {
    const { template } = useGetters(['template'])

    const component = computed<VueConstructor<Vue>>(
      () => pages[template.value] || Subpage
    )

    return {
      component,
    }
  },
})
