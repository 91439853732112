























































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import BigCTA from '@/components/BigCTA.vue'
import ProductMainCategory from '@/components/products/ProductMainCategory.vue'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'products',
  components: {
    BigCTA,
    ProductMainCategory,
    LineSplitter,
  },
  setup() {
    const { content } = useGetters(['content'])
    const titleRef = ref()
    const subtitleRef = ref()
    const categoriesRef = ref()

    onMounted(() => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.25')
        }

        if (categoriesRef.value) {
          categoriesRef.value.forEach((el, i) => {
            const element = el.$el.querySelector('[data-toggle]')
            const content = element.querySelectorAll('[data-toggle-content]')
            // eslint-disable-next-line no-mixed-operators
            const offset = 0.25 + i * 0.2
            TL.to(
              element,
              {
                '--progress': 1,
              },
              `start+=${offset}`
            ).fromTo(
              content,
              {
                opacity: 0,
                y: 10,
              },
              {
                opacity: 1,
                y: 0,
                stagger: 0.1,
              },
              `start+=${offset}`
            )
          })
        }
      }
    })

    return {
      content,
      titleRef,
      subtitleRef,
      categoriesRef,
    }
  },
})
