




















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import SingleWrapper from '@/components/SingleWrapper.vue'
import Slideshow from '@/components/Slideshow.vue'
import KeyPoints from '@/components/KeyPoints.vue'
import BigCTA from '@/components/BigCTA.vue'

export default defineComponent({
  name: 'about',
  components: { SingleWrapper, Slideshow, KeyPoints, BigCTA },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
