






































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import SingleWrapper from '@/components/SingleWrapper.vue'
import Slideshow from '@/components/Slideshow.vue'
import KeyPoints from '@/components/KeyPoints.vue'
import FeaturedNews from '@/components/FeaturedNews.vue'
import BigCTA from '@/components/BigCTA.vue'
import Strech from '@/components/animation/Strech.vue'
import { motionOK } from '@/inc/utils/motionOK'

export default defineComponent({
  name: 'about',
  components: {
    SingleWrapper,
    Slideshow,
    KeyPoints,
    FeaturedNews,
    BigCTA,
    Strech,
  },
  setup() {
    const { content } = useGetters(['content'])

    const additionalContentRef = ref()
    const animateIn = () => {
      if (motionOK() && additionalContentRef.value) {
        additionalContentRef.value.getTween()
      }
    }

    return {
      content,
      animateIn,
      additionalContentRef,
    }
  },
})
