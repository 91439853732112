
















import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import VueRecaptcha from 'vue-recaptcha'

export default defineComponent({
  components: { VueRecaptcha },
  props: {
    theme: {
      type: String,
      required: false,
      default: 'light',
    },
    size: {
      type: String,
      required: false,
      default: 'normal',
    },
    value: {
      type: String,
    },
  },
  setup(_props, ctx) {
    const captcha = ref()

    const { currentLang, chrome } = useGetters(['currentLang', 'chrome'])

    const onVerify = res => {
      ctx.emit('input', res)
    }

    const onError = () => {
      ctx.emit('input', null)
    }

    const reset = () => {
      captcha.value.reset()
    }

    return {
      onVerify,
      onError,
      reset,
      captcha,
      chrome,
      currentLang,
    }
  },
})
