

















































































































































































































































































































































































































































/* eslint-disable quote-props */
import { defineComponent, ref, onMounted, reactive } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import WebP from '@/components/WebP.vue'
import Accordion from '@/components/Accordion.vue'
import ContactForm from '@/components/ContactForm.vue'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import HorizontalLine from '@/components/animation/HorizontalLine.vue'
import Strech from '@/components/animation/Strech.vue'
import ZoomOutImg from '@/components/animation/ZoomOutImg.vue'

export default defineComponent({
  components: {
    WebP,
    ContactForm,
    Accordion,
    LineSplitter,
    HorizontalLine,
    Strech,
    ZoomOutImg,
  },
  setup() {
    const { content } = useGetters(['content'])

    const contactForms = reactive(
      content.value.content.services.items.map(() => ({
        isOpen: false,
      }))
    )

    const contactFormsAccordions = ref()

    const collapseContactForm = i => {
      contactForms[i].isOpen = false
      contactFormsAccordions.value[i].$el.dispatchEvent(new Event('toggle'))
    }
    const visualRef = ref()
    const heroVisualContainerRef = ref()

    // Instead of straight up using internal anchor
    // we want to land at the top of the page, then animate scroll to the desired section
    // so the user have more contextual information and know that "there is some other content above"
    // The "quote form" section has a specific behavior. Hence the hardcoded values.
    const autoScrollToService = () => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const service = urlParams.get('service')

      if (service === 'quote') {
        contactForms[0].isOpen = true

        if (contactFormsAccordions.value[0]) {
          contactFormsAccordions.value[0].$el.scrollIntoView({
            behavior: 'smooth',
          })
        }
      } else if (service === 'technical-support') {
        contactForms[1].isOpen = true

        const target = document.querySelector('[data-service="service-1"]')

        let top = target?.getBoundingClientRect().top || 0
        top -=
          document.querySelector('header.header')?.getBoundingClientRect()
            .height || 0

        window.scrollTo({
          top,
          behavior: 'smooth',
        })
      }
    }

    onMounted(() => {
      // Wait a bit before auto scrolling otherwise there is too much animation happening
      window.setTimeout(autoScrollToService, 1500)

      if (motionOK()) {
        if (visualRef.value) {
          const imgEl = visualRef.value.$el.querySelector('img')

          if (imgEl) {
            gsap.to(imgEl, {
              '--scale': 1,
              duration: 5,
              ease: 'power4.out',
            })

            gsap.to(imgEl, {
              opacity: 0.2,
              '--translateY': '-30%',
              scrollTrigger: {
                trigger: heroVisualContainerRef.value,
                scrub: true,
                start: 'top top',
                end: 'bottom top',
              },
            })
          }
        }

        if (servicesButtonRef.value) {
          servicesButtonRef.value.forEach(el => {
            gsap.set(el.$el, {
              opacity: 0,
              yPercent: 100,
            })
          })
        }

        if (faqLinkRef.value) {
          gsap.set(faqLinkRef.value.$el, {
            opacity: 0,
            yPercent: 100,
          })
        }
      }
    })

    const introTitleRef = ref()
    const introSubtitleRef = ref()
    const introAnimateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (introTitleRef.value) {
          TL.add(introTitleRef.value.getTween(), 'start')
        }
        if (introSubtitleRef.value) {
          TL.add(introSubtitleRef.value.getTween(), 'start+=0.25')
        }
      }
    }

    const titleRef = ref()
    const baselineRef = ref()
    const servicesHrRef = ref()
    const servicesTitleRef = ref()
    const servicesContentRef = ref()
    const servicesButtonRef = ref()
    const formSectionAnimateIn = () => {
      if (motionOK()) {
        const stagger = 0.075
        let index = 0

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (baselineRef.value) {
          index += 1
          TL.add(baselineRef.value.getTween(), `start+=${index * stagger}`)
        }

        content.value.content.services.items.forEach((_service, i) => {
          if (servicesHrRef.value[i]) {
            index += 1
            TL.add(
              servicesHrRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
          if (servicesTitleRef.value[i]) {
            index += 1
            TL.add(
              servicesTitleRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
          if (servicesContentRef.value[i]) {
            index += 1
            TL.add(
              servicesContentRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
          if (servicesButtonRef.value[i]) {
            index += 1
            TL.to(
              servicesButtonRef.value[i].$el,
              { opacity: 1, yPercent: 0 },
              `start+=${index * stagger}`
            )
          }
        })
      }
    }

    const otherServicesBaselineRef = ref()
    const otherServicesHrRef = ref()
    const otherServicesTitleRef = ref()
    const otherServicesContentRef = ref()
    const faqHrRef = ref()
    const faqTitleRef = ref()
    const faqLinkRef = ref()
    const otherServicesAnimateIn = () => {
      if (motionOK()) {
        const stagger = 0.075
        let index = 0

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (otherServicesBaselineRef.value) {
          TL.add(otherServicesBaselineRef.value.getTween(), 'start')
        }

        content.value.content.otherServices.items.forEach((_service, i) => {
          if (otherServicesHrRef.value[i]) {
            index += 1
            TL.add(
              otherServicesHrRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
          if (otherServicesTitleRef.value[i]) {
            index += 1
            TL.add(
              otherServicesTitleRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
          if (otherServicesContentRef.value[i]) {
            index += 1
            TL.add(
              otherServicesContentRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
        })

        if (faqHrRef.value) {
          index += 1
          TL.add(faqHrRef.value.getTween(), `start+=${index * stagger}`)
        }
        if (faqTitleRef.value) {
          index += 1
          TL.add(faqTitleRef.value.getTween(), `start+=${index * stagger}`)
        }
        if (faqLinkRef.value) {
          index += 1
          TL.to(
            faqLinkRef.value.$el,
            {
              opacity: 1,
              yPercent: 0,
            },
            `start+=${index * stagger}`
          )
        }
      }
    }

    const addressTitleRef = ref()
    const adressesHrRef = ref()
    const adressesContentRef = ref()
    const addressesAnimateIn = () => {
      if (motionOK()) {
        const stagger = 0.075
        let index = 0

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (addressTitleRef.value) {
          TL.add(addressTitleRef.value.getTween(), 'start')
        }

        content.value.content.address.items.forEach((_address, i) => {
          if (adressesHrRef.value[i]) {
            index += 1
            TL.add(
              adressesHrRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
          if (adressesContentRef.value[i]) {
            index += 1
            TL.add(
              adressesContentRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
        })
      }
    }

    return {
      content,
      collapseContactForm,
      visualRef,
      heroVisualContainerRef,
      introTitleRef,
      introSubtitleRef,
      introAnimateIn,
      titleRef,
      baselineRef,
      servicesHrRef,
      servicesTitleRef,
      servicesContentRef,
      servicesButtonRef,
      formSectionAnimateIn,
      otherServicesAnimateIn,
      otherServicesBaselineRef,
      otherServicesHrRef,
      otherServicesTitleRef,
      otherServicesContentRef,
      faqHrRef,
      faqTitleRef,
      faqLinkRef,
      addressTitleRef,
      adressesHrRef,
      adressesContentRef,
      addressesAnimateIn,
      contactForms,
      contactFormsAccordions,
    }
  },
})
