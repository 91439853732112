





















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { Pages } from '@/views/Page.vue'
import SingleWrapper from '@/components/SingleWrapper.vue'
import BigCTA from '@/components/BigCTA.vue'

/* eslint-disable camelcase */
const subpages: Pages = {}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'subpage',
  components: { SingleWrapper, BigCTA },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
      subpages,
    }
  },
})
