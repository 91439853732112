var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"hero pt-xl"},[(_vm.content && _vm.content.picture)?_c('div',{ref:"heroVisualContainerRef",staticClass:"hero__visual-container"},[_c('WebP',{ref:"visualRef",staticClass:"hero__visual",attrs:{"picture":_vm.content.picture,"className":"hero__visual","sets":[640, 960, 1280, 1440, 1600, 1920, 2240, 2560, 2880]}})],1):_vm._e(),_c('div',{directives:[{name:"enter-viewport",rawName:"v-enter-viewport"}],staticClass:"row",on:{"enterViewport":_vm.introAnimateIn}},[_c('div',{staticClass:"col-xxs-offset-2 col-xxs-20 col-m-13"},[(_vm.content.title)?_c('LineSplitter',{ref:"introTitleRef",staticClass:"page-title intro__title",attrs:{"content":_vm.content.title,"type":'h1'}}):_vm._e(),(_vm.content.subtitle)?_c('LineSplitter',{ref:"introSubtitleRef",staticClass:"page-subtitle intro__subtitle alt-heading",attrs:{"content":_vm.content.subtitle,"type":'h2'}}):_vm._e()],1)])]),_c('main',[_c('section',[(_vm.content.content.services)?_c('div',{directives:[{name:"enter-viewport",rawName:"v-enter-viewport"}],staticClass:"section surface--dark",on:{"enterViewport":_vm.formSectionAnimateIn}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 col-l-10 col-l-offset-6"},[(_vm.content.content.title)?_c('LineSplitter',{ref:"titleRef",staticClass:"contact-title",attrs:{"content":_vm.content.content.title,"type":'h3'}}):_vm._e(),(_vm.content.content.services.label)?_c('LineSplitter',{ref:"baselineRef",staticClass:"contact-baseline",attrs:{"content":_vm.content.content.services.label,"type":'h4'}}):_vm._e()],1)]),_vm._l((_vm.content.content.services.items),function(service,i){return _c('div',{key:i,class:{
            'mb-xl': i < _vm.content.content.services.items.length - 1,
          },attrs:{"data-service":service.id || ("service-" + i)}},[_c('div',{staticClass:"row service"},[_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 col-l-16 col-l-offset-6"},[_c('HorizontalLine',{ref:"servicesHrRef",refInFor:true})],1),_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 col-l-8 col-l-offset-6"},[(service.title)?_c('LineSplitter',{ref:"servicesTitleRef",refInFor:true,staticClass:"main-service-title",attrs:{"content":service.title,"type":'h5'}}):_vm._e()],1),_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 col-l-7 col-l-offset-1"},[(service.htmltext)?_c('Strech',{ref:"servicesContentRef",refInFor:true},[_c('g-wysiwyg',{attrs:{"wysiwyg":service.htmltext}})],1):_vm._e()],1)]),(service.form)?_c('Accordion',{ref:"contactFormsAccordions",refInFor:true,attrs:{"expanded":_vm.contactForms[i].isOpen}},[_c('div',{staticClass:"row end-xxs pt-l pb-s"},[_c('button',{staticClass:"button form-close",on:{"click":function($event){_vm.contactForms[i].isOpen = false}}},[_c('svg',{attrs:{"width":"42","height":"20","viewBox":"0 0 42 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"0.608859","y1":"18.0811","x2":"40.6089","y2":"1.08114","stroke":"white","stroke-width":"2"}}),_c('line',{attrs:{"x1":"1.39121","y1":"1.0797","x2":"41.3899","y2":"18.0828","stroke":"white","stroke-width":"2"}})])])]),_c('ContactForm',{attrs:{"form":service.form,"fileUploadError":service.fileUploadError,"missingCaptcha":service.missingCaptcha,"formSubmissionError":service.formSubmissionError,"formSubmissionSuccess":service.formSubmissionSuccess,"id":i},on:{"submit":function($event){return _vm.collapseContactForm(i)}}})],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 col-l-7 col-l-offset-15"},[(
                  !_vm.contactForms[i].isOpen &&
                  service.form &&
                  service.form.label &&
                  service.form.formId &&
                  service.form.endpoint
                )?_c('Curtain',{ref:"servicesButtonRef",refInFor:true,staticClass:"button--has-curtain--dark--fullwidth mt-s",attrs:{"label":service.form.label,"type":"button"},nativeOn:{"click":function($event){_vm.contactForms[i].isOpen = true}}}):_vm._e()],1)])],1)})],2):_vm._e(),(_vm.content.content.otherServices)?_c('div',{directives:[{name:"enter-viewport",rawName:"v-enter-viewport"}],staticClass:"section surface--accent",on:{"enterViewport":_vm.otherServicesAnimateIn}},[_c('div',{staticClass:"row"},[(_vm.content.content.otherServices.label)?_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 col-l-16 col-l-offset-6"},[_c('LineSplitter',{ref:"otherServicesBaselineRef",staticClass:"contact-baseline",attrs:{"content":_vm.content.content.otherServices.label,"type":'h4'}})],1):_vm._e(),_vm._l((_vm.content.content.otherServices.items),function(service,i){return _c('div',{key:i,staticClass:"col-xxs-20 col-xxs-offset-2 col-s-9 col-l-7 mb-xl other-service",class:{
              'col-l-offset-6': i % 2 === 0,
              'col-l-offset-2': i % 2 !== 0,
            }},[_c('HorizontalLine',{ref:"otherServicesHrRef",refInFor:true}),_c('LineSplitter',{ref:"otherServicesTitleRef",refInFor:true,staticClass:"contact-title",attrs:{"content":service.title,"type":'h5'}}),(service.htmltext)?_c('Strech',{ref:"otherServicesContentRef",refInFor:true},[_c('g-wysiwyg',{attrs:{"wysiwyg":service.htmltext}})],1):_vm._e()],1)}),(
              _vm.content.content.faq.title ||
              (_vm.content.content.faq &&
                _vm.content.content.faq.link &&
                _vm.content.content.faq.link.label &&
                _vm.content.content.faq.link.url)
            )?_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 col-l-16 col-l-offset-6"},[_c('HorizontalLine',{ref:"faqHrRef"}),_c('LineSplitter',{ref:"faqTitleRef",staticClass:"contact-title",attrs:{"content":_vm.content.content.faq.title,"type":'h5'}}),(
                _vm.content.content.faq &&
                _vm.content.content.faq.link &&
                _vm.content.content.faq.link.label &&
                _vm.content.content.faq.link.url
              )?_c('Curtain',{ref:"faqLinkRef",staticClass:"button--has-curtain--light",attrs:{"label":_vm.content.content.faq.link.label,"url":_vm.content.content.faq.link.url}}):_vm._e()],1):_vm._e()],2)]):_vm._e()]),_c('section',{staticClass:"section bleed-section surface--light"},[_c('div',{staticClass:"relative pb-xxxl"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2"},[(_vm.content.content.address.picture)?_c('ZoomOutImg',{staticClass:"mb-xl",attrs:{"picture":_vm.content.content.address.picture,"sets":[2000]}}):_vm._e()],1)]),_c('div',{directives:[{name:"enter-viewport",rawName:"v-enter-viewport"}],staticClass:"row",on:{"enterViewport":_vm.addressesAnimateIn}},[_c('div',{staticClass:"col-xxs-18 col-xxs-offset-4 col-l-16 col-l-offset-6"},[(_vm.content.content.address.title)?_c('LineSplitter',{ref:"addressTitleRef",staticClass:"contact-title mb-l",attrs:{"content":_vm.content.content.address.title,"type":'h3'}}):_vm._e()],1),_vm._l((_vm.content.content.address.items),function(address,i){return _c('address',{key:i,staticClass:"address col-xxs-18 col-xxs-offset-4 col-l-7",class:{
              'col-l-offset-6': i % 2 === 0,
              'col-l-offset-2': i % 2 !== 0,
            }},[_c('HorizontalLine',{ref:"adressesHrRef",refInFor:true}),(address.title || address.htmltext)?_c('Strech',{ref:"adressesContentRef",refInFor:true},[_c('div',{staticClass:"contact-baseline address__title"},[_vm._v(" "+_vm._s(address.title)+" ")]),(address.htmltext)?_c('g-wysiwyg',{attrs:{"wysiwyg":address.htmltext}}):_vm._e()],1):_vm._e()],1)})],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row bleed-section__background-container"},[_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 bleed-container"},[_c('div',{staticClass:"bleed"})])])}]

export { render, staticRenderFns }