



































































































































































































































































































































































/* eslint-disable camelcase */
/* eslint-disable quote-props */
import { defineComponent, reactive, ref, onMounted } from '@vue/composition-api'
import axios from 'axios'
import jsonToFormData from 'json-form-data'
import FloatingLabelSelect from '@/components/FloatingLabelSelect.vue'
import FloatingLabel from '@/components/FloatingLabel.vue'
import TextBox from '@/components/TextBox.vue'
import FileUpload from '@/components/FileUpload.vue'
import Captcha from '@/components/Captcha.vue'
import { push } from '@/inc/utils'
import { pushForm, formStart } from '@/inc/utils/tracking'
// Import { pushForm, formStart } from '@/utils/tracking'

export default defineComponent({
  name: 'contact-form',
  components: {
    FloatingLabelSelect,
    FloatingLabel,
    TextBox,
    FileUpload,
    Captcha,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    fileUploadError: {
      type: String,
    },
    missingCaptcha: {
      type: String,
    },
    formSubmissionError: {
      type: String,
    },
    formSubmissionSuccess: {
      type: String,
    },
    id: {
      required: false,
    },
  },

  setup(props, ctx) {
    const fileInput = ref()
    const feedbackSuccess = ref('')
    const feedbackFailure = ref('')
    const isLoading = ref(false)
    const captcha = ref()
    const formRef = ref()

    const subjectInputRef = ref()
    const entrepriseInputRef = ref()
    const vatInputRef = ref()
    const firstNameInputRef = ref()
    const lastNameInputRef = ref()
    const emailInputRef = ref()
    const phoneInputRef = ref()
    const addressInputRef = ref()
    const numberInputRef = ref()
    const cityInputRef = ref()
    const zipInputRef = ref()
    const messageInputRef = ref()

    const data = reactive({
      subject: '',
      entreprise: '',
      vat: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      number: '',
      city: '',
      zip: '',
      message: '',
      file: '',
      'g-recaptcha-response': '',
      form_id: props.form.formId,
      action: 'form',
    })

    onMounted(() => {
      formStart(formRef.value)
    })

    const onSubmit = () => {
      pushForm('click')
      feedbackSuccess.value = ''
      feedbackFailure.value = ''
      if (validateCaptcha()) {
        isLoading.value = true
        fileInput.value
          .upload()
          .then(response => {
            if (response.fileUploaded) {
              const url = new URL(props.form.fields.upload.button.url)
              data.file = `${url.origin}${url.pathname}`
            }
            submitForm()
          })
          .catch(error => {
            isLoading.value = false
            resetCaptcha()
            // TODO: ADD TRANSLATION
            feedbackFailure.value =
              props.fileUploadError ||
              // eslint-disable-next-line quotes
              "Une erreur est survenue lors de l'upload de fichier. Votre demande de devis n'a pas pu etre envoyée."
            console.error(error)
          })
      } else {
        // TODO: ADD TRANSLATION
        feedbackFailure.value =
          props.missingCaptcha || 'Veuillez remplir le captcha.'
      }
    }

    const validateCaptcha = () => data['g-recaptcha-response']

    const submitForm = () => {
      const formData = jsonToFormData(data)

      axios
        .post(`${props.form.endpoint}?action=form`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if (response.data.status === 200) {
            success()
          } else {
            failure(response)
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const success = () => {
      // TODO: ADD TRANSLATION
      feedbackSuccess.value =
        props.formSubmissionSuccess ||
        // eslint-disable-next-line quotes
        'Votre demande de devis a bien été envoyée. Nous vous recontacterons dans les plus bref délais.'

      // Push success
      pushForm('success')
      push({
        event: 'devis-success',
        eventCategory: 'demande-de-devis-success',
        eventAction: 'click-formulaire',
        eventLabel: data.subject || 'dépannage',
      })

      resetForm()

      // Emit a submit event that will be catched by accordion parent component that will collapse
      window.setTimeout(() => {
        ctx.emit('submit')

        feedbackSuccess.value = ''
        feedbackFailure.value = ''
      }, 2500)
    }

    const failure = error => {
      resetCaptcha()

      // TODO: ADD TRANSLATION
      feedbackFailure.value =
        props.formSubmissionError ||
        // eslint-disable-next-line quotes
        "Une erreur est survenue lors de l'envoi de votre demande de devis. Celui ci n'a pas pu aboutir."

      // Push error
      pushForm('error')
      push({
        event: 'devis-fail',
        eventCategory: 'demande-de-devis-fail',
        eventAction: 'click-formulaire',
        eventLabel: data.subject || 'dépannage',
      })

      console.error(error)
    }

    const resetCaptcha = () => {
      data['g-recaptcha-response'] = ''
      captcha.value.reset()
    }

    const resetForm = () => {
      if (subjectInputRef.value) {
        subjectInputRef.value.reset()
      }
      entrepriseInputRef.value.reset()
      vatInputRef.value.reset()
      firstNameInputRef.value.reset()
      lastNameInputRef.value.reset()
      emailInputRef.value.reset()
      phoneInputRef.value.reset()
      addressInputRef.value.reset()
      numberInputRef.value.reset()
      cityInputRef.value.reset()
      zipInputRef.value.reset()
      messageInputRef.value.reset()
    }

    return {
      formRef,
      data,
      captcha,
      onSubmit,
      fileInput,
      isLoading,
      feedbackSuccess,
      feedbackFailure,
      subjectInputRef,
      entrepriseInputRef,
      vatInputRef,
      firstNameInputRef,
      lastNameInputRef,
      emailInputRef,
      phoneInputRef,
      addressInputRef,
      numberInputRef,
      cityInputRef,
      zipInputRef,
      messageInputRef,
    }
  },
})
