







































/* eslint-disable capitalized-comments */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { debounce } from 'throttle-debounce'

export default defineComponent({
  name: 'collapse',
  setup() {
    const collapsed = ref(true)
    const contentRef = ref()
    const contentWrapperRef = ref()
    const contentHeight = 0

    const toggle = () => {
      // contentWrapperRef.value.style.height = collapsed.value
      //   ? `${contentHeight}px`
      //   : `${0}px`
      // collapsed.value = !collapsed.value
      // window.dispatchEvent(new Event('resize'))
      // window.setTimeout(() => {
      //   window.dispatchEvent(new Event('resize'))
      // }, 400)
      if (collapsed.value) {
        contentWrapperRef.value.style.display = 'block'
        collapsed.value = false
      } else {
        contentWrapperRef.value.style.display = 'none'
        collapsed.value = true
      }
    }

    // const getContentHeight = () =>
    //   contentRef.value.getBoundingClientRect().height

    // const onResize = debounce(250, () => {
    //   contentHeight = getContentHeight()

    //   if (!collapsed.value) {
    //     contentWrapperRef.value.style.height = `${contentHeight}px`
    //   }
    // })

    onMounted(() => {
      contentWrapperRef.value.style.display = 'none'
      // contentWrapperRef.value.style.height = `${0}px`
      // contentHeight = getContentHeight()
      // window.addEventListener('resize', onResize)
    })

    onUnmounted(() => {
      // window.removeEventListener('resize', onResize)
    })

    return {
      contentRef,
      contentWrapperRef,
      toggle,
      collapsed,
    }
  },
})
