













































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import FeaturedReferences from '@/components/FeaturedReferences.vue'
import BigCTA from '@/components/BigCTA.vue'
import SolutionsOverview from '@/components/SolutionsOverview.vue'
import SolutionPreview from '@/components/SolutionPreview.vue'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import Strech from '@/components/animation/Strech.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'solutions',
  components: {
    FeaturedReferences,
    BigCTA,
    SolutionsOverview,
    LineSplitter,
    Strech,
    SolutionPreview,
  },
  setup() {
    const { content } = useGetters(['content'])

    const formatIndex = i => (i + 1).toString().padStart(2, '0')

    const titleRef = ref()
    const subtitleRef = ref()
    const contentRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.1')
        }
      }
    }

    const contentAnimateIn = () => {
      if (motionOK() && contentRef.value) {
        contentRef.value.getTween()
      }
    }

    return {
      content,
      formatIndex,
      titleRef,
      subtitleRef,
      contentRef,
      animateIn,
      contentAnimateIn,
    }
  },
})
