







































































































import { defineComponent } from '@vue/composition-api'
import ProductCategory from '@/components/products/ProductCategory.vue'
import ProductList from '@/components/products/ProductList.vue'
import Collapse from '@/components/Collapse.vue'

export default defineComponent({
  name: 'product-main-category',
  components: { Collapse, ProductCategory, ProductList },
  props: {
    category: {
      required: true,
      type: Object,
    },
    depth: {
      required: true,
      type: Number,
    },
  },
  setup() {
    const titleColumnClass = {
      0: 'col-xxs-20 col-xxs-offset-2 col-xl-16 col-xl-offset-6',
      1: 'col-xxs-19 col-xxs-offset-3 col-l-16 col-l-offset-6 col-xl-12 col-xl-offset-10',
    }

    const count = collection => {
      let total = 0

      if (collection.items && collection.items.length > 0) {
        total += collection.items.length
      }

      if (collection.children && collection.children.length > 0) {
        for (let i = 0; i < collection.children.length; i++) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          total += count(collection.children[i])
        }
      }

      return total
    }

    return {
      titleColumnClass,
      count,
    }
  },
})
