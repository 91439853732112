





























import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'

export default defineComponent({
  props: {
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const accordion = ref()
    const accordionInner = ref()
    const height = ref(0)
    const resizeObserver = ref()

    onMounted(() => {
      updateHeight()
      resizeObserver.value = new ResizeObserver(() => {
        updateHeight()
      })
      resizeObserver.value.observe(accordionInner.value)
    })

    onBeforeUnmount(() => {
      resizeObserver.value.unobserve(accordionInner.value)
    })

    const updateHeight = () => {
      height.value = accordionInner.value.getBoundingClientRect().height
      accordion.value.style.setProperty('--height', `${height.value}px`)
    }

    const onToggle = () => {
      accordion.value.classList.toggle('expanded')
    }

    return {
      accordion,
      accordionInner,
      onToggle,
    }
  },
})
