<template>
  <div class="row top-xxs" v-enter-viewport @enterViewport="animateIn">
    <div class="col-xxs-20 col-xxs-offset-2 col-m-9 visual-container">
      <ZoomOutImg
        v-if="solution.picture1"
        :picture="solution.picture1"
        class="visual--primary"
        :sets="[800]"
        ref="primaryVisualRef"
        :autoplay="false"
      />
      <ZoomOutImg
        v-if="solution.picture2"
        :picture="solution.picture2"
        class="visual--secondary"
        :sets="[350]"
        ref="secondaryVisualRef"
        :autoplay="false"
      />
    </div>
    <div class="col-xxs-20 col-xxs-offset-2 col-m-8 col-m-offset-3">
      <h2 class="solution__title">
        <SolutionTitle
          :index="index"
          :solution="solution.title"
          :icon="solution.icon"
          :animate="true"
          ref="titleRef"
        />
      </h2>

      <Strech ref="contentRef">
        <g-wysiwyg :wysiwyg="solution.content" class="mb-l"></g-wysiwyg>

        <IconLink
          v-if="solution.link && solution.link.url && solution.link.label"
          :href="solution.link.url"
          :label="solution.link.label"
          :target="solution.link.target"
        />
      </Strech>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import SolutionTitle from '@/components/SolutionTitle.vue'
import IconLink from '@/components/IconLink.vue'
import ZoomOutImg from '@/components/animation/ZoomOutImg.vue'
import Strech from '@/components/animation/Strech.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  props: {
    solution: {
      type: Object,
    },
    index: {
      type: Number,
    },
    ltr: {
      type: Boolean,
    },
  },
  components: {
    SolutionTitle,
    IconLink,
    ZoomOutImg,
    Strech,
  },
  setup() {
    const primaryVisualRef = ref()
    const secondaryVisualRef = ref()
    const titleRef = ref()
    const contentRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (primaryVisualRef.value) {
          TL.add(primaryVisualRef.value.getTween(), 'start')
        }
        if (secondaryVisualRef.value) {
          TL.add(secondaryVisualRef.value.getTween(), 'start')
        }
        TL.add(titleRef.value.getTween(), 'start+=0.2')
        TL.add(contentRef.value.getTween(), 'start+=0.6')
      }
    }

    return {
      primaryVisualRef,
      secondaryVisualRef,
      titleRef,
      contentRef,
      animateIn,
    }
  },
})
</script>
